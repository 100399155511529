function nodeFromString(htmlString) {
    let div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
}

function nodesFromString(htmlString) {
    let div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return [...div.children];
}

export {nodeFromString, nodesFromString}
