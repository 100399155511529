import SingleImageSaver from "./SingleImageSaver";

export default class SingleImageCropper extends SingleImageSaver {
    getOptions() {
        super.getOptions()

        if (this.input.hasAttribute('ratio')) {
            this.options.ratio = this.input.getAttribute('ratio') ?? null
        }
    }

    run() {
        let image = this.getFileInputImage()

        if (image) {
            window.imageCropper.run(image, this.options.ratio).then(result => this.setResult(result))
        }
        this.container.fileInput.value = ''
    }
}

